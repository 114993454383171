<template>
  <div class="container-upload parent">
    <r-icon
      v-if="icon"
      icon="warning"
      fill="fargo"
      size="56"
    />
    <h1 class="ricotta mb-4 mt-7">
      {{ title || 'Удалить объект?' }}
    </h1>
    <div class="mb-8 font">
      {{ subtitle || 'Это действие невозможно будет отменить' }}
    </div>
    <div class="mt-2 buttons">
      <r-button
        class="flex-1"
        color="secondary"
        @click.native="close()"
        :title="buttons[0] || 'Не удалять'"
        width="wide"
      />
      <r-button
        class="flex-1"
        @click.native.prevent.stop="Delete"
        :title="buttons[1] || 'Да, удалить'"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';

export default {
  name: 'DeleteModal',
  props: {
    icon: {
      type: Boolean,
      default: true
    },
    action: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => (['Не удалять', 'Да, удалить'])
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    },
    async Delete() {
      this.$props.action();
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media (max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 30%;
  margin-left: 21%;
}
</style>
