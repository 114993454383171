<template>
  <div class="flex align-center justify-center">
    <img
      class="modal-view-image__view"
      :src="file"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: 'ModalViewImage',
  props: {
    file: {
      type: [Object, String]
    }
  }
};
</script>

<style lang="scss">
.modal-view-image{
  &__view{
    width: 100%;
    max-width: 600px;
  }
}
</style>
