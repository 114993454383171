<template>
  <div>
    <router-link :to="`edit/${card.id}`">
      <div class="card">
        <div class="flex">
          <div class="card__image mr-3">
            <img
              v-show="!!card.photo1 && loadImage"
              :src="card.photo1"
              :alt="card.typeS"
              @load="loadImage = true"
            >
            <r-icon
              v-show="!(!!card.photo1 && loadImage)"
              size="32"
              icon="trade-objects"
              fill="alien"
            />
          </div>
          <div>
            <p class="sulguni">
              {{ card.typeS }}
            </p>
            <p class="mozzarella opacity-72 mt-1">
              {{ card.address }}
            </p>
            <div class="flex align-center mt-3">
              <p class="briscola mr-5 flex align-center">
                <r-icon
                  class="mr-2"
                  icon="blueprint"
                  fill="rocky"
                />
                {{ card.areaS }} м<sup style="font-size: 10px;">2</sup>
              </p>
              <p
                class="briscola flex align-center"
                v-if="!!Number(card.contractConclude)"
              >
                <r-icon
                  class="mr-2"
                  icon="selected"
                  fill="rocky"
                />
                {{ card.dateToS ? ('Договор до ' + getFormattedDate(card.dateToS)) : '—' }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="flex align-center ml-auto"
          v-if="this.$store.getters.userIsRoot"
        >
          <additional-menu>
            <ul class="card__menu">
              <li
                class="sulguni flex pointer opacity-72 mb-6"
                @click.stop.prevent="copyItem"
              >
                <r-icon
                  class="mr-3"
                  icon="copy"
                  fill="rocky "
                />
                Дублировать
              </li>
              <li
                class="sulguni flex pointer opacity-72"
                @click.stop.prevent="openDeleteModal"
              >
                <r-icon
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                />
                Удалить
              </li>
            </ul>
          </additional-menu>
        </div>
      </div>
    </router-link>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';
import DeleteModal from './DeleteModal';
import { formattedDate } from '../helpers/utils';
import Api from '../api/Api';

export default {
  name: 'Card',
  components: { AdditionalMenu },
  props: {
    card: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowPopover: false,
      loadImage: false
    };
  },
  methods: {
    async openDeleteModal() {
      await this.$refs.modal.openModal(DeleteModal, {
        title: 'Удалить объект?',
        subtitle: 'Это действие невозможно будет отменить',
        buttons: ['Не удалять', 'Да, удалить'],
        action: () => { this.delete(this.$props.card.id); }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'delete'
      }).then(() => {
        window.location.reload();
      });
    },
    copyItem() {
      this.$router.push({ path: `edit/${this.card.id}`, query: { copy: true } });
    },
    getFormattedDate(date) {
      return formattedDate(date);
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px;
  border-radius: 16px;

  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }

  .pointer {
    cursor: pointer;
  }
  &__image{
    width: 72px;
    height: 72px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #E4EDFB;
    flex-shrink: 0;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.popover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.popover__content {
  overflow: visible !important;
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
}

::v-deep .rir-tooltip {
  display: flex;
  align-items: center;
}

::v-deep .rir-tooltip__wrapper {
  z-index: 1;
  top: unset !important;
  bottom: calc(100% + 6px);
  max-width: 400px;
}

::v-deep .card__menu {
  li {
    &:hover {
      opacity: 1;
    }
  }
}
</style>
