import axios from 'axios';

export default class ApiService {
  token = null;
  _axios = null;
  constructor() {
    // this.token = Vue.prototype.$keycloak.kk.token;
    this._axios = axios.create({
      baseURL: process.env.VUE_APP_API_SERVER,
      headers: {
        'Content-Type': 'application/json'
        // common: {
        //   Authorization: `Bearer ${Vue.prototype.$keycloak.kk.token}`
        // }
      }
    });
    window._axios = this._axios;
  }
}
