<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <loader v-show="isLoading" />

    <div v-show="!isLoading">
      <admin-header
        :is-save="!isSave && isEditPage"
        :title="
          !isEditPage ? 'Новый объект' : formData.typeS || 'НТО'
        "
      />
      <div class="form mt-8 flex flex-1">
        <div class="flex flex-1 flex-column">
          <div class="flex">
            <img-uploader
              class="mr-6"
              @change="onUpload"
              @onViewImage="onViewImage"
              @deleteFile="deleteFile"
              upload-text="Загрузить фото"
              :src="formData.photo1"
            />
            <div class="flex-1">
              <div class="field_wrapper mb-6">
                <r-input
                  label="Наименование"
                  v-model.trim="$v.formData.typeS.$model"
                  @blur="isChange('typeS')"
                  :class="{ field_error: $v.formData.typeS.$error }"
                />
                <div
                  class="error mozzarella fargo--text bottom"
                  v-if="$v.formData.typeS.$error && !$v.formData.typeS.required"
                >
                  Поле обязательно для заполнения
                </div>
              </div>
              <div class="flex align-center flex-1">
                <div class="field_wrapper mr-6 flex-1">
                  <r-input
                    label="Площадь, м²"
                    v-model.trim="$v.formData.areaS.$model"
                    @blur="isChange('areaS')"
                    :class="{ field_error: $v.formData.areaS.$error }"
                    :params-input="{
                      type: 'number'
                    }"
                  />
                  <div
                    class="error mozzarella fargo--text bottom"
                    v-if="$v.formData.areaS.$error && !$v.formData.areaS.required"
                  >
                    Поле обязательно для заполнения
                  </div>
                </div>
                <div class="field_wrapper flex-1">
                  <r-input
                    label="Количество мест"
                    v-mask="'###########'"
                    v-model.trim="$v.formData.places.$model"
                    @blur="isChange('places')"
                    :class="{ field_error: $v.formData.places.$error }"
                  />
                  <div
                    class="error mozzarella fargo--text bottom"
                    v-if="$v.formData.places.$error && !$v.formData.places.required"
                  >
                    Поле обязательно для заполнения
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field_wrapper mt-6">
            <r-textarea
              :class="{ field_error: $v.formData.spec.$error }"
              @input="isChange('spec')"
              :rows="6"
              label="Специализация"
              v-model.trim="$v.formData.spec.$model"
            />
            <div
              class="error mozzarella fargo--text bottom"
              v-if="$v.formData.spec.$error && !$v.formData.spec.required"
            >
              Поле обязательно для заполнения
            </div>
          </div>
          <div class="field_wrapper mt-6">
            <r-textarea
              @input="isChange('description')"
              :rows="6"
              label="Описание"
              v-model.trim="formData.description"
            />
          </div>
          <r-checkbox
            v-model="formData.contractConclude"
            title="Заключён договор"
            class="mt-6"
            style="width: max-content;"
            @input="isChange('contractConclude')"
          />
          <r-date-picker
            class="mt-6"
            :readonly="!formData.contractConclude"
            style="max-width: 280px"
            click-close
            label="Дата окончания договора"
            v-model="formData.contractDateEnd"
            @input="isChange('contractDateEnd')"
          />
          <div class="field_wrapper mt-6">
            <div class="flex align-start flex-1">
              <div class="flex-1 mr-2">
                <r-input
                  label="Телефон"
                  @onPressEnter="addPhone"
                  v-model="phone"
                  v-mask="'+7 (###) ###-##-##'"
                />
                <selected-list
                  v-show="formData.phones && formData.phones.length"
                  class="mt-4"
                  icon="telephone"
                  v-model="formData.phones"
                  text-value=""
                />
              </div>
              <r-button-simple
                size="larishae"
                type="primary"
                color="rocky"
                @click="addPhone"
                icon="add"
                :disabled="!phone"
              />
            </div>
          </div>
          <div class="flex align-start flex-1 mt-6">
            <div class="flex-1 mr-2">
              <r-input
                label="Имейл"
                @onPressEnter="addEMail"
                v-model="email"
              />
              <selected-list
                v-show="formData.emails && formData.emails.length"
                class="mt-4"
                icon="mail"
                v-model="formData.emails"
              />
            </div>
            <r-button-simple
              size="larishae"
              type="primary"
              color="rocky"
              @click="addEMail"
              icon="add"
              :disabled="!email"
            />
          </div>
          <r-checkbox
            v-model="formData.hidden"
            title="Скрывать НТО"
            class="mt-10"
            style="width: max-content;"
            @input="hideChange"
          />
          <p class="briscola opacity-48 ml-7">
            Объект не будет отображаться пользователям
          </p>

          <r-date-picker
            class="mt-6"
            :readonly="formData.hidden"
            style="max-width: 280px"
            click-close
            label="Дата размещения, до"
            v-model="formData.dateToS"
            @input="isChange('contractDateEnd')"
          />

          <button
            v-if="isEditPage && $store.getters.userIsRoot"
            class="flex fargo--text sulguni mt-10"
            color="transparent"
            content-width
            @click="onDelete"
          >
            <r-icon
              class="mr-2"
              icon="delete"
              fill="fargo"
            />
            Удалить НТО
          </button>
        </div>
        <div
          class="ml-6 flex flex-column flex-1 overflow-hidden"
          :key="count"
        >
          <rir-map
            @click="onMapClick"
            collapse-btn
            style="height: 415px"
            class="mb-6"
            :center="formData.lat && formData.lng ? [formData.lat, formData.lng] : $cityCenter"
          >
            <ymap-marker
              :coords="formData.lat && formData.lng ? [formData.lat, formData.lng] : $cityCenter"
              marker-id="marker"
              :options="{
                draggable: this.$store.getters.userIsRoot,
              }"
              :icon="formData.contractConclude ? $markerIcon('mapPinRed') : $markerIcon('mapPin')"
              @dragend="onMarkerDragEnd"
            />
          </rir-map>

          <div class="field_wrapper mb-6">
            <r-input
              class="flex-1"
              label="Адрес"
              before-icon="search"
              size="eluno"
              :is-clear-model-string="true"
              v-model="$v.formData.address.$model"
              :class="{ field_error: $v.formData.address.$error }"
              @blur="onSearchAddress(formData.address)"
              @onPressEnter="onSearchAddress(formData.address)"
            />
            <div
              class="error mozzarella fargo--text bottom"
              v-if="$v.formData.address.$error && !$v.formData.address.required"
            >
              Поле обязательно для заполнения
            </div>
          </div>
        </div>
      </div>
      <div class="button_container">
        <r-button
          @click="submit"
          :disabled="isLoading || isSave"
          :title="isEditPage ? 'Сохранить' : 'Добавить'"
          width="wide"
        />
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Loader from '@/components/Loader.vue';
import moment from 'moment';
import AdminHeader from '../components/AdminHeader';
import ModalCrop from '../components/ModalCrop';
import ModalViewImage from '../components/ModalViewImage';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import { formattedDate } from '../helpers/utils';
import RirMap from '../components/RirMap';
import SelectedList from '../components/SelectedList';
import ImgUploader from '../components/ImgUploader';

export default {
  name: 'Nto',
  components: { Loader, AdminHeader, RirMap, SelectedList, ImgUploader },
  data() {
    return {
      count: 0,
      canChange: false,
      isSave: false,
      flyButton: null,
      isLoading: false,
      isMapReady: false,
      emptyFormData: {},
      initialData: {},
      loadedImg: null,
      phone: null,
      email: null,
      formData: {
        photo1: null,
        typeS: null,
        address: null,
        lat: null,
        lng: null,
        areaS: null,
        dateToS: null,
        places: null,
        spec: null,
        description: null,
        contractConclude: false,
        contractDateEnd: null,
        phones: [],
        emails: [],
        hidden: false
      }
    };
  },
  validations: {
    formData: {
      typeS: {
        required
      },
      address: {
        required
      },
      areaS: {
        required
      },
      places: {
        required
      },
      spec: {
        required
      }
    }
  },
  computed: {
    markerCoords() {
      const { lat } = this;
      const { lng } = this;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    isEditPage() {
      return !!this.$route.params.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  created() {
    this.emptyFormData = this.formData;
  },
  mounted() {
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    if (!this.isEditPage) {
      this.isSave = false;
    }
  },
  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData();

    if (!this.isEditPage) {
      this.isSave = false;
    }

    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
    this.activeTabId = 'information';
  },
  methods: {
    hideChange() {
      this.formData.dateToS = null;
      this.isChange('hidden');
    },
    addPhone() {
      this.formData.phones.push(this.phone);
      this.phone = null;
      this.isChange('phones');
    },
    addEMail() {
      this.formData.emails.push(this.email);
      this.email = null;
      this.isChange('emails');
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$refs.modal.openModal(ModalCrop, {
        image: this.loadedImg,
        valName: 'photo1'
      });
      this.isChange('photo1');
    },
    onViewImage() {
      this.$refs.modal.openModal(ModalViewImage, {
        file: this.formData.photo1
      });
    },
    deleteFile() {
      this.formData.photo1 = null;
    },
    async onSearchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.formData.address;
      }

      const res = await api.getAddress(JSON.stringify(data));
      console.log(res);
      this.formData.lat = res.lat;
      this.formData.lng = res.lng;
      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.formData.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.formData.address = res.address;
        }
      }
      this.isChange('address');
    },

    async onDelete() {
      await this.$refs.modal.openModal(DeleteModal, {
        title: 'Удалить НТО?',
        action: () => { this.delete(this.$route.params.id); }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'delete'
      }).then(result => {
        const r = this.$router.resolve({
          name: 'index'
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
    },
    dateFilter(e) {
      let date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();
      if (date == 'Invalid Date') {
        date = moment(e, 'DD.MM.YYYY').format('YYYY-MM-DD');

        return date;
      }

      return `${yy}-${mm}-${dd}`;
    },
    async setData() {
      this.isLoading = true;
      await this.$store.dispatch('getObjectById', this.$route.params.id).then(res => {
        this.formData = { ...this.formData, ...res.all[0] };
        this.formData.contractConclude = this.formData.contractDateEnd ? true
          : (this.formData.contractConclude == '1');
        this.formData.hidden = !!Number(this.formData.hidden);
        this.formData.contractDateEnd = this.formData.contractDateEnd ? this.dateFilter(this.formData.contractDateEnd) : null;
        this.formData.dateToS = this.formData.dateToS ? this.dateFilter(this.formData.dateToS) : null;
        if (this.isEditPage) {
          this.isSave = true;
        }
        this.count++;
      }).finally(() => {
        this.isLoading = false;
        this.canChange = true;
      });
    },
    onMarkerDragEnd(e) {
      if (!this.$store.getters.userIsRoot) return;
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.lat = coords[0];
        this.lng = coords[1];
        this.onSearchAddress(coords);
      }
    },

    async onMapClick(coords) {
      if (!this.$store.getters.userIsRoot) return;
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.onSearchAddress(coords);
    },

    resetData() {
      this.$v.$reset();
      this.formData = JSON.parse(JSON.stringify(this.emptyFormData));
    },
    async submit(send = false) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const data = {
          id: this.$route.params.id ? this.$route.params.id : -1,
          action: 'update',
          item: this.formData
        };
        if (this.isCopy) {
          data.id = -1;
        }
        new Api()
          .operateObject(data)
          .then(result => {
            const r = this.$router.resolve({
              name: 'index'
            });
            if (!result?.error) {
              window.location.assign(r.href);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    isChange(field) {
      if (!this.isCopy && this.canChange) {
        this.isSave = this.initialData[field] === this.formData[field];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.width-max {
  width: max-content !important;
}

.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

::v-deep .rir-modal__content {
  display: block !important;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.rotate-animation {
  animation: spin .8s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.flex-col, .flex-column {
  flex-direction: column;
}
.fargo {
  background-color: unset;
}
</style>
