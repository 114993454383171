<template>
  <div>
    <h1>Ошибка 404</h1>
    <h2>Страница не найдена</h2>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
div {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}
</style>
