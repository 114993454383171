<template>
  <div class="modal_upload parent">
    <p class="ricotta mb-6">
      Новые данные
    </p>
    <upload-file
      ref="childComponent"
      @uploadEvent="uploadFile"
      @uploadError="uploadError"
      @PreloadFile="preloadFile"
      :accept-file="['.xls, .xlsx, .csv']"
      :count-file="2"
      upload-url="/ajax.php?action=universalImport"
      :value="fileUpload"
      :count-line="1"
      description="или перетяните сюда файл xls, созданый на основе шаблона"
    />
    <a
      class="flex sulguni mt-6 rocky--text"
      href="https://base.rosatom.city/ajax.php?getImportXLStemplateForLayer=sarovyama_kiosks"
    >
      <r-icon
        icon="load"
        class="mr-1"
      />
      Скачать шаблон для импорта данных в csv
    </a>
    <div
      class="flex mt-8"
    >
      <p class="text-fargo mb-3">
        {{ error }}
      </p>
      <r-button
        class="flex-1"
        @click="clickUpload"
        :disabled="loading || !isPreloadFile"
        :title="loading ? 'Загрузка...' : 'Загрузить'"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
import UploadFile from './UploadFile';

export default {
  name: 'ModalUpload',
  components: {
    UploadFile
  },
  data() {
    return {
      isPreloadFile: false,
      error: '',
      loading: false,
      fileUpload: []
    };
  },
  methods: {
    preloadFile(e) {
      this.isPreloadFile = e;
    },
    clickUpload() {
      this.loading = true;
      this.$refs.childComponent.handleFiles();
    },
    uploadFile(file) {
      this.loading = false;
      console.log(file);
    },
    uploadError(err) {
      this.loading = false;
      this.error = err;
      console.log(err);
    }
  }
};
</script>
<style scoped>
.modal_upload{
  width: 100%;
  max-width: 600px;
}
.text-align-center{
  text-align: center;
}
.loading {
  display:inline-block;
  clip-path: inset(0 1.5ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -0.5ch 0 0)
  }
}
.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 30%;
  margin-left: 31%;
}
</style>
