<template>
  <div
    class="r-map-balloon pointer"
    :data-id="marker.id"
  >
    <div
      class="r-map-balloon__img"
      v-show="!!marker.photo1 && loadImage"
    >
      <div
        class="backdrop"
        :style="`background-image: url('${marker.photo1}')` "
      />
      <img
        :src="marker.photo1"
        :alt="marker.typeS"
        @load="loadImage = true"
      >
    </div>
    <div class="pa-4">
      <p class="roquefort mb-1">
        {{ marker.typeS }}
      </p>
      <p class="сaprino mozzarella opacity-72">
        {{ marker.address }}
      </p>
      <div class="flex align-center justify-space-between mt-4">
        <button
          transparent
          class="rocky--text mr-3 hover-opacity"
          @click.stop="copyItem"
        >
          <r-icon
            class="mr-3"
            icon="copy"
            fill="rocky"
            size="16"
          />
          Дублировать
        </button>
        <button
          transparent
          @click.stop="deleteItem"
          class="hover-opacity ml-auto"
        >
          <r-icon
            class="mr-3"
            icon="delete"
            fill="fargo"
            size="16"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    openDeleteModal: {
      type: Function,
      default: () => {}
    },
    marker: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loadImage: false
    };
  },
  methods: {
    copyItem() {
      this.$router.push({ path: `edit/${this.marker.id}`, query: { copy: true } });
    },
    deleteItem() {
      this.$props.openDeleteModal(this.marker.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  &__img {
    width: 100%;
    height: 125px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    .backdrop{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: blur(10px);
      z-index: -1;
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__info {
    font-family: 'Golos UI';
    padding: 12px 16px 0;
  }
}

</style>
